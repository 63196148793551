import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import axios from "axios";

import Layout from "../components/layout"
import Seo from "../components/seo"

const description = "tio offers better digital experiences for D2C brands to support them digitally."

const MeetPage = () => {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    text: "init"
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      text: `Thanks!`
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true, text: "Loading..." });
    axios({
      method: "post",
      url: "https://getform.io/f/0c15c4fc-a0b5-4e2c-b024-fca05475e590",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "thanks", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };


  return (
    <Layout navbarTheme="dark" className="bg-cream" >
      <Seo title="meet tio" description={description} canonical="https://tio.ist/meet-tio/" />
      <div className="relative p-10 lg:py-20">

        <div className="absolute -top-10 lg:top-0 right-0 lg:right-48">
          <StaticImage quality="100" className="" src="../images/d2c-markalar.svg" width={150} alt="D2C Markalar" />
        </div>
        <div className="absolute max-w-max invisible lg:bottom-20 lg:visible left-0 lg:right-20">
          <StaticImage className="" src="../images/sol-ok.svg" width={350} alt="Tio" />
        </div>
        <div className="container text-xl lg:text-3xl text-center font-light mx-auto max-w-2xl">
          <h1 className="text-4xl lg:text-6xl px-10 mb-5 mt-10 lg:mt-0 lg:mb-20 font-bold text-blue">
            We provide digital partnership to brands.
          </h1>
          <p >
            We are looking for D2C brands with the goal of creating a good <b className="font-bold">digital experience</b> for their customers.
          </p>
          <Link to="#meet-tio" className="underline mt-20 text-blue text-xl block">Meet Tio Digital Solutions Team.</Link>
        </div>
      </div>
      <div className=" relative p-10 py-20">
        <div className="absolute max-w-max invisible lg:visible z-1 -top-10 lg:top-32 right-0 lg:right-0">
          <StaticImage className="" src="../images/right-circle.svg" width={200} alt="Tio" />
        </div>
        <div className="absolute z-1 lg:p-0 -top:10 lg:top-48  left-32 lg:right-20">
          <StaticImage className="" src="../images/is-buyut.png" width={100} alt="Tio ile işinizi büyütün" />
        </div>
        <div className="container mt-32 lg:mt-0 text-xl lg:text-2xl text-left lg:text-center font-light mx-auto max-w-xl">
          <h2 className="text-4xl lg:text-6xl  py-10 font-bold text-blue">Grow your business in digital with tio.</h2>
          <p className=" pb-5">
            Tio provides tools and services to help you manage your <b className="font-bold">production, sales, orders, deliveries and stocks</b> easier and more effecient.
          </p>
          <p className=" pb-5">
            We are working as <b className="font-bold">digital partners</b> to create a better workflow from production to delivery and sales management.
          </p>
          <p className=" ">
            We support you with <b className="text-blue font-bold">tio team</b> along with our products.
          </p>
          <div className="block my-20 text-xl">
            <Link to="#meet-tio" className="block lg:inline underline py-5 text-blue">Call now →</Link>
            <Link to="#meet-tio" className="block lg:inline bg-blue text-white p-2 lg:m-5">Grow your business with tio!</Link>
          </div>
        </div>
      </div>

      <div className=" relative p-10 py-20">
        <div className="xl:absolute max-w-max z-1 -top-5 left-0 lg:right-0 ">
          <StaticImage className="" src="../images/tio-boost.svg" width={430} alt="Grow your business with tio!" />
        </div>
        <div className="container text-xl lg:text-2xl text-left lg:pl-20 font-light mx-auto max-w-3xl">
          <h2 className="text-6xl py-10 font-bold text-blue">We want to be your digital partner.</h2>
          <p className=" pb-5">
            Manage your sales from different channels on one platform: Work 10x faster with connected orders, deliveries, invoices in one platform. Grow your business with live data analytics.
          </p>
          <p className=" pb-5">
            Everything you need is in one digital platform to manage your business.
          </p>
          <p className=" ">
            We support you with <b className="text-blue font-bold">tio team</b> along with our products.
          </p>
          <div className="block my-20 text-xl">
            <Link to="#meet-tio" className="underline py-10 text-blue">Get your Tio Boost Meeting</Link>
            <p className="block max-w-sm text-lg py-5">( 🚀 Tio Boost is a 20 minute interview where we talk what could you do to grow your your business in digital. )</p>
          </div>
        </div>
      </div>

      <div className=" relative p-10 py-20 mt-20 lg:mt-0">
        <div className="absolute z-1 -right-20 -top-48 lg:-top-32 lg:right-0">
          <StaticImage className="" src="../images/tio-light.svg" width={430} alt="Grow your business with tio!" />
        </div>
        <div className="container mt-20 text-xl lg:px-32">
          <div className="lg:flex">
            <div className="lg:w-1/2 lg:p-10 relative">
              <h2 className="text-5xl max-w-xs font-bold text-blue ">Meet Tio Digital Solutions Team!</h2>
              <div className="hidden lg:block">
                <Link to="#meet-tio" className="py-10 block max-w-xs underline text-blue">Make your workflow flawless with tio solutions. ↗</Link>
                <div className="w-1/2 text-center block p-10">
                  <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Grow your business with tio!" />
                </div>
                <div className="w-1/2 text-center block p-10">
                  <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Grow your business with tio!" />
                </div>
              </div>
            </div>

            <ul className="list-disc max-w-xl p-10 font-light">
              <li className="py-3">Our team will analyze your workflow.</li>
              <li className="py-3">A <b className="font-bold">road map</b> including which steps should be taken for a <b className="font-bold">better</b> digital workflow will be created.</li>
              <li className="py-3">We decide together how we should start the application of the roadmap.</li>
              <li className="py-3">You can start using your new system in 1 week. <span className="text-pink">*</span>
                <p className="text-md mt-10 font-italic" ><span className="text-pink">*</span> The duration might change depending on the size of data to transfer, spesifications and the structure of the previous system.</p>

              </li>
            </ul>
          </div>
          <div id="meet-tio" className="text-center p-10">
            <div className="lg:hidden relative">
              <Link to="#meet-tio" className="py-10 block max-w-xs underline text-blue">Make your workflow flawless + all digital with tio solutions. ↗</Link>
              <div className="lg:w-1/2 text-center block p-10">
                <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Grow your business with tio!" />
              </div>
              <div className="lg:w-1/2 text-center block p-10">
                <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Grow your business with tio!" />
              </div>
            </div>
            {!serverState.status?.ok ? 
              <form id="form" name="meet" method="post" onSubmit={handleOnSubmit} >
                <input type="hidden" name="form-name" value="meet" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="name" placeholder="Name Surname *" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="companyName" placeholder="Company Name *" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="email" name="email" placeholder="E-mail address *" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="phone" placeholder="Phone Number *" />
                <select className="bg-white max-w-sm w-full border border-blue p-3" name="companySize">
                  <option disabled selected>Company Size</option>
                  <option value="1-9">1-9 Employees</option>
                  <option value="10-49">1-49 Employees</option>
                  <option value="50-249">50-249 Employees</option>
                  <option value="250+">250+ Employees</option>
                </select>
                <textarea className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required rows="5" name="message" placeholder="Your message"/>
                <button className="bg-blue px-5 rounded mt-5 py-2 text-white" type="submit" >Send</button>
              </form> :
              <div className="max-w-sm w-full mx-auto">
                <p className="text-xl lg:text-2xl text-center">{serverState.text}</p>
              </div>
            }
          </div>
        </div>
      </div>


    </Layout>
  )
}

export default MeetPage
